<template>
    <section v-loading="loading_recursos" :element-loading-text="`${mensaje_carga}`">
        <header-page title="Editar Tema" ruta-atras="mis-cursos.editar.curso.tema" :params="{id_curso:tema.id_curso}" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 220px);">
            <div class="row mx-0 h-100 py-3">
                <div class="col-7 border-right">
                    <div class="row mx-0 j-center">
                        <div class="col-10 my-2">
                            <el-input v-model="tema.nombre" class="w-100 input-name" placeholder="Nombre..." />
                        </div>
                        <div class="col-10 my-2">
                            <label class="f-15 text-gris"> Descripción </label>
                        </div>
                        <div class="col-10">
                            <quill-editor
                            ref="myQuillEditor"
                            v-model="tema.contenido"
                            class="quill-radius"
                            :options="editorOption"
                            />
                        </div>
                    </div>
                    <div class="btn btn-general br-12 px-3 mt-3  text-white shadow position-absolute" style="right:95px;" @click="updateTema">
                        Actualizar Tema
                    </div>
                </div>
                <div class="col-5 border-left pt-4 px-4">
                    <p class="text-gris">
                        Seleccione los archivos que desea subir.
                    </p>
                    <el-upload
                    ref="upload"
                    class="upload-demo my-3"
                    action="#"
                    :auto-upload="false"
                    accept="'image/jpg','image/jpeg','image/png','image/gif','video/mp4','application/pdf'"
                    :on-change="subir_recurso_aws"
                    :show-file-list="false"
                    multiple
                    :limit="10"
                    >
                        <div class="bg-white br-12 py-2 px-5 text-gris shadow">
                            Agregar Archivos
                        </div>
                    </el-upload>

                    <draggable v-model="tema" handle=".handle" @change="cambioPosicion">
                        <div v-for="(r, index) in tema.recursos" :key="index" class="row mx-0 my-2 a-center">
                            <div class="col-auto px-1">
                                <i class="icon-drag handle f-18 text-general" />
                            </div>
                            <div class="col-6 px-0 tres-puntos">
                                <i class="text-general f-15" :class="icon_extension(r.nombre)" />
                                {{ r.nombre }}
                            </div>
                            <div class="col-auto d-flex align-items-center text-general cr-pointer f-12" :class="r.descargable ? 'text-general': 'text-light-gris'">
                                <el-switch
                                v-model="r.descargable"
                                active-color="#13ce66"
                                @change="updateDescarga(r)"
                                />
                                <span class="ml-2">
                                    Descargable
                                </span>

                                <i class="icon-cancel f-18 text-general" @click="deleteRecursoTema(r.id)" />
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
import Aws from '~/services/aws'
export default {
    layout: 'fullscreen',
    data(){
        return{
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction

                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],

                        ['clean']                                         // remove formatting button
                    ]
                },
            },
            model_recursos:[],
            mensaje_carga:'',
            contador:0,
            contTemp:1,
            loading_recursos:false,
            tema:'',
            value1: 0
        }
    },
    computed:{
        id_tema(){
            return Number(this.$route.params.id_tema)
        }
    },
    mounted(){
        this.infoTema()
    },
    methods: {
        async infoTema(){
            try {
                const { data } = await Cursos.infoTema(this.id_tema)
                this.tema = data.data.tema
            } catch (error){
                this.error_catch(error)
            }
        },
        async updateTema(){
            try {

                if(this.tema.nombre == '' || this.tema.contenido == ''){
                    this.notify('Alerta, Los campos son requeridos','warning')
                    this.infoTema()
                    return
                }

                let model = {
                    nombre:this.tema.nombre,
                    contenido:this.tema.contenido
                }
                
                const {data} = await Cursos.updateTema(this.id_tema, model)
                this.notify('Tema actualizado correctamente','success')
                
            } catch (error){
                this.infoTema()
                this.error_catch(error)
            }
        },
        async subir_recurso_aws(file,filelist){
            let that = this
            try {
                let extenciones = ['image/jpg','image/jpeg','image/png','image/gif','video/mp4','application/pdf']  
                let extensionValida = extenciones.includes(file.raw.type)
                const isVideo = this.file_type(file.raw.name) === 'video/*'
                const isLt100M = file.size / 1000 / 1000 < 100;
                const isVIDEOLt500M = file.size / 1024 /1024 < 500
                if(!extensionValida){
                    filelist.splice(file,1)
                    this.notify(`El arhivo ${file.name} no es valido`,'warning')
                    return
                }
                if(isVideo && !isVIDEOLt500M){
                    filelist.splice(file,1)
                    this.notify(`El video ${file.name}  excede los 500MB`,'warning')
                    return
                }
                if (!isVideo && !isLt100M){
                    filelist.splice(file,1)
                    this.notify(`El archivo ${file.name} excede los 100MB`,'warning')
                    return
                }
                that.loading_recursos = true
                that.contador = filelist.length
                let ruta = `descargables/${Aws.getHash()}.${file.name.split('.').pop()}`
                let resp = await Aws.getInstance().upload({
                    Key: ruta,
                    ContentType: file.raw.type,
                    Body: file.raw
                }).on('httpUploadProgress',uploadEvent=>{
                    let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    that.mensaje_carga = `Recursos ${that.contTemp}/${filelist.length} => ${porcentaje}%`
                }).promise()
                that.contTemp++
                this.model_recursos.push({ruta:resp.Key, nombre:file.name,peso: file.size,tipo: this.file_type(file.raw.name),descargable:0})
                that.contador--
                if(that.contador === 0){
                    that.almacenarRecursos()
                }
            } catch (e){
                this.loading_recursos = false
                this.model_recursos = []
                this.error_catch(e)
            }
            
        },
        async almacenarRecursos(){

            try {
                if(!this.id_tema){
                    this.notificacion('Alerta','Accion Invalidad, Por favor seleccione un tema valido','warning')
                    return 
                }
                const {data} = await Cursos.almacenarRecursosTemas(this.id_tema,this.model_recursos)
                this.infoTema()
                this.notify('Recursos almacenados correctamente','success') 
            } catch (e){
                this.error_catch(e)
            }finally{
                this.mensaje_carga = '',
                this.contador = 0,
                this.contTemp = 1,
                this.$refs.upload.clearFiles()
                this.loading_recursos = false,
                this.model_recursos = []
            }

        },
        async updateDescarga(obj){
            try {
                let archivo=obj.archivo
                let divisiones=archivo.split(".")
                let tipo= divisiones.pop()
                let extenciones = ['jpg','jpeg','png','gif','mp4','pdf'];

                if(extenciones.includes(tipo)){
                    const {data} = await Cursos.updateDescarga(obj.id)
                    obj.descargable = !obj.descargable 
                    this.notify('Se ha cambiado el estado','success')
                }else{
                    this.notify('Este archivo es de tipo no descargable','warning')
                }
                this.infoTema()
                
                

                

            } catch (error){
                this.infoTema()
                this.error_catch(error)
            }
        },
        async cambioPosicion({moved}){
            try {
                let model = {
                    nuevaPosicion:moved.newIndex + 1,
                    antiguaPosicion:moved.oldIndex + 1
                }
                
                const {data} = await Cursos.updatePosicionRecursoTema(this.id_tema,model)
                this.notify('Éxito, Posición Actualizada','success')

            } catch (error){
                this.infoTema()
                this.error_catch(error)  
            }
        },
        async deleteRecursoTema(id_recurso_tema){
            try {
                
                await Cursos.deleteRecursoTema(id_recurso_tema)
                this.notify('Eliminado, Registro removido correctamente','success')
                this.infoTema()

            } catch (error){
                this.error_catch(error) 
            }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>